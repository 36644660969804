// Eldhose John 20240115 @SumUps.com

export default function Loading(props) {
  if(props.loading > 0)
    return (
      <nav className='Loading'>
        <div className='animation-loading' />
      </nav>
    );
  else
    return ;
}
