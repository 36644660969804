// Eldhose John 20240129 @SumUps.com
import { useEffect, useRef, useState } from 'react';
import Toolbar from './Toolbar';
import Text from '../elem/Text';

export default function Editor(props) {
  const editorRef = useRef(null);
  const editRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const onInput = (e) => {
    if(typeof props.onChange === 'function')
      props.onChange(e.target.innerHTML);
  }
  const placeholder = () => (
    (typeof props.placeholder === 'string') ?  props.placeholder : ''
  );
  useEffect(() => {
    if(
      loaded === false && editRef.current !== null &&
      editRef.current.innerHTML === '' && props.value !== ''
    ) {
      setLoaded(true);
      editRef.current.innerHTML = props.value;
    }
  }, [loaded, props.value, setLoaded]);
  return (
    <div>
      <Text className='Small'>{placeholder()}</Text>
      <div className='Editor' ref={editorRef}>
        <Toolbar editorRef={editorRef} editRef={editRef} />
        <div
          contentEditable={true}
          onInput={onInput}
          ref={editRef}
          suppressContentEditableWarning={true}
          style={(typeof props.style === 'object') ? props.style : {}}
        />
      </div>
    </div>
  );
}
