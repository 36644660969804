// Eldhose John 20240303 @SumUps.com
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { globalFunctionUse } from '../lib/Global';
import Input from '../elem/Input';

export default function User(props) {
  const Message = globalFunctionUse('Message');
  const handleError = (usr) => {
    if(
      ((typeof props.emptyValid === 'boolean') ? props.emptyValid : false) &&
      usr === ''
    )
      props.setError(false);
    else
      props.setError(!(
        /^[A-Za-z](?=.*[0-9])[A-Za-z0-9_.-]{6,}$/.test(usr)
      ));
  }
  const handleBlur = () => (handleError(props.user));
  const handleChange = (e) => {
    props.setUser(e.target.value);
    handleError(e.target.value);
  }
  const placeholder = () => (
    (typeof props.placeholder === 'string') ? props.placeholder : Message(
      '542fc71480dec743e60c20434ec6dd5838fc4b52', 'User name'
    )
  )
  return (
    <Input
      error={props.error}
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder={placeholder()}
      preicon={<FontAwesomeIcon icon={faUser} />}
      value={props.user}
    />
  );
}
