// Eldhose John 20240123 @SumUps.com

export default function Text(props) {
  const className = () => {
    let cn = 'Text';
    const cns = (
      (typeof props.className === 'string') ? props.className : 'Normal'
    ).split(' ');
    for(let i = 0; i < cns.length; i++)
      cn += (' Text' + cns[i]);
    return cn;
  }
  return (
    <span className={className()}>
      {props.children}
    </span>
  );
}
