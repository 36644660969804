// John Eldhose 20240114 @SumUps.com

export default function Left() {
  return (
    <nav className='Left Left-xl'>
      <div>
      </div>
    </nav>
  );
}
