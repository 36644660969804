// Eldhose John 20240206 @SumUps.com
import Text from '../elem/Text';

export default function Error(props) {
  return (
    <nav className='Error'>
      <img src='/error.png' alt='error' />
      <div>
        <div><Text className='Title'>Something went wrong</Text></div>
        <div><Text className='Subtitle'>{props.error}</Text></div>
        <div><Text>Please click on the logo to continue.</Text></div>
      </div>
    </nav>
  );
}

