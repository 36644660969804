// Eldhose John 20240130 @SumUps.com
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug, faCircleCheck, faCircleInfo, faTriangleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons';
import { globalFunctionSet } from '../lib/Global';
import Text from '../elem/Text';

export default function Notify() {
  const [messages, setMessages] = useState([]);
  const handleNotify = (item) => {
    let ts = new Date().getTime();
    let msgs = [];
    if(typeof item === 'object') {
      item.ts = ts + 14000;
      msgs.push(item);
    }
    let to = 14000;
    if(messages.length > 0) {
      for(let i = 0; i < messages.length; i++) {
        if(
          messages[i].ts > ts &&
          (typeof item !== 'number' || item !== i)
        ) {
          msgs.push(messages[i]);
          if(messages[i].ts < to)
            to = messages[i].ts;
        }
      }
    }
    setMessages(msgs);
    if(msgs.length > 0) {
      setTimeout(() => handleNotify('x'), to);
    }
  }
  globalFunctionSet('Notify', (item) => handleNotify(item));
  const icon = (type) => {
    switch(type) {
      case 'Err':
        return faBug;
      case 'Ok':
        return faCircleCheck;
      case 'Warn':
        return faTriangleExclamation;
      default:
        return faCircleInfo;
    }
  }
  const message = (msg) => {
    return ((msg.length > 75) ? (msg.substring(0, 70) + ' . . .') : msg);
  }
  return (
    <>
      {messages.map((item, i) => (
        <nav
          className={'Notify Notify' + item.type}
          key={i}
          style={{bottom: ((i * 4) + 1) + 'rem'}}
        >
          <FontAwesomeIcon icon={icon(item.type)} />
          <Text>{message(item.message)}</Text>
          <button onClick={() => handleNotify(i)}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </nav>
      ))}
    </>
  );
}
