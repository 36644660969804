// John Eldhose 20240114 @SumUps.com
import { globalFunctionUse } from './Global';

export function locationGet() {
  let obj = {
    page: (
      (typeof window.location.pathname === 'string') ?
        window.location.pathname : ''
    ),
    parms: {}
  };
  let search = (typeof window.location.search === 'string') ?
    window.location.search : '';
  if(search !== '')
    search = search.replace(/[\s/?]+/, '');
  if(search !== '') {
    Object.entries(search.split('&')).forEach(([i, str]) => {
      if(typeof str === 'string' && str !== '' && str.includes('=')) {
        const [key, val] = str.split('=');
        if(val === 'true') obj.parms[key] = true;
        else if(val === 'false') obj.parms[key] = false;
        else obj.parms[key] = decodeURIComponent(val);
      }
    });
  }
  return obj;
}
export function locationLoad(url) {
  window.location = url;
}
const makeUrl = (obj) => {
  let url = (typeof obj.page === 'string') ? obj.page : '';
  if( url !== '') {
    url = url.replace(/[\s/]+/, '');
    url = url.replace(/[\s/?]+$/, '');
  }
  let parms = '';
  if(typeof obj.parms === 'object') {
    Object.entries(obj.parms).forEach(([key, val]) => {
      if(val !== '') {
        if(parms !== '') parms += '&';
        parms += key + '=' + encodeURIComponent(val);
      }
    });
  }
  return ('/' + url + ((parms === '') ? '' : ('?' + parms)));
}
export function locationReplace(obj, refresh = false) {
  window.history.replaceState(null, '', makeUrl(obj));
  if(refresh) {
    const Refresh = globalFunctionUse('Refresh');
    Refresh(obj);
  }
}
export function locationSet(obj) {
  let url = makeUrl(obj);
  if(url !== (window.location.pathname + window.location.search)) {
    window.history.pushState(locationGet(), '', url);
    const Refresh = globalFunctionUse('Refresh');
    onpopstate = (e) => (Refresh(locationGet()));
    Refresh(obj);
  }
}
