// John Eldhose 20240114 @SumUps.com

export default function Right() {
  return (
    <nav className='Right Right-xl'>
      <div>
      </div>
    </nav>
  );
}
