// John Eldhose 20240122 @SumUps.com

export default function Button(props) {
  return (
    <button
      className='Button'
      disabled={(typeof props.disabled === 'boolean') ? props.disabled : false}
      onClick={
        (typeof props.onClick === 'function') ? props.onClick : () => {}
      }
    >
      {props.children}
    </button>
  );
}
