// Eldhose John 20240129 @SumUps.com
import Text from './Text';

export default function Textarea(props) {
  const handleChange = (e) => {
    if(typeof props.onChange === 'function') props.onChange(e);
  }
  const maxlength = () => {
    if(typeof props.maxlength !== 'undefined')
      return props.maxlength;
    if(typeof props.maxLength !== 'undefined')
      return props.maxLength;
    return 350;
  }
  const placeholder = () => (
    (typeof props.placeholder === 'string') ?  props.placeholder : ''
  );
  return (
    <div>
      <Text className='Small'>{placeholder()}</Text>
      <div className='Textarea'>
        <textarea
          autoCapitalize='sentences'
          cols={(typeof props.cols === 'number') ? props.cols : 50}
          onChange={handleChange}
          maxLength={maxlength()}
          placeholder={placeholder()}
          rows={(typeof props.rows === 'number') ? props.rows : 7}
          value={(typeof props.value === 'string') ? props.value : ''}
        >
        </textarea>
      </div>
    </div>
  );
}
