// Eldhose John 20240201 @SumUps.com
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import Text from './Text';

export default function Dropdown(props) {
  const [display, setDisplay] = useState('none');
  const handleClose = (e) => {
    handleClick(false, e);
  }
  const handleChange = (e) => {
    if(typeof props.onChange === 'function')
      props.onChange(e);
  }
  const handleClick = (on, e) => {
    const disp = (on && display === 'none') ? 'block' : 'none';
    setDisplay(disp);
    if(disp === 'block')
      setTimeout(() => document.addEventListener('click', handleClose), 100);
    else
      document.removeEventListener('click', handleClose);
  }
  const isButton = () => (typeof props.onChange === 'undefined');
  const handleClickInput = (e) => {
    if(isButton())
      handleClick(true, e);
  }
  const handleFocus = (e) => {
    if(typeof props.onFocus === 'function')
      props.onFocus(e);
  }
  const handleSelect = (key) => {
    if(typeof props.onSelect === 'function')
      props.onSelect(key);
    handleClick(false, null);
  }
  const label = (key) => {
    if(typeof props.items[key].label === 'undefined')
      return key;
    return props.items[key].label;
  }
  const maxlength = () => {
    if(typeof props.maxlength !== 'undefined')
      return props.maxlength;
    if(typeof props.maxLength !== 'undefined')
      return props.maxLength;
    return 100;
  }
  const placeholder = () => {
    return ((typeof props.placeholder === 'string') ? props.placeholder : '');
  }
  const preicon = () => {
    if (typeof props.preicon == 'undefined')
      return ;
    return (<span>{props.preicon}</span>);
  }
  const preiconitem = (key) => {
    if(typeof props.items[key].preicon === 'undefined')
      return ;
    return props.items[key].preicon;
  }
  const value = () => {
    if(typeof props.value === 'string') {
      if(
        typeof props.items[props.value] === 'object' &&
        typeof props.items[props.value].label === 'string'
      )
        return props.items[props.value].label;
      else
        return props.value;
    }
    return '';
  }
  return (
    <div>
      <Text className='Small'>{placeholder()}</Text>
      <div className='Dropdown'>
        {preicon()}
        <input
          className={isButton() ? 'DropdownButton' : 'DropdownInput'}
          maxLength={maxlength()}
          onChange={handleChange}
          onClick={handleClickInput}
          onFocus={handleFocus}
          placeholder={placeholder()}
          type={isButton() ? 'button' : 'text'}
          value={value()}
        />
        <button onClick={(e) => handleClick(true, e)}>
          <FontAwesomeIcon
            icon={display === 'none' ? faCaretDown : faCaretUp}
          />
        </button>
      </div>
      <div className='DropdownMenu' style={{display: display}} tabIndex='-1'>
        {Object.keys(props.items).map((key, i) => (
          <div key={i} onClick={() => handleSelect(key)}>
            {preiconitem(key)}{label(key)}
          </div>
        ))}
      </div>
    </div>
  );
}
