// Eldhose John 20240129 @SumUps.com
import Text from './Text';

export default function Upload(props) {
  const handleChange = (e) => {
    if(typeof props.onChange === 'function') props.onChange(e);
  }
  const placeholder = () => (
    (typeof props.placeholder === 'string') ? props.placeholder : ''
  );
  return (
    <div className='Upload'>
      <Text className='Small'>{placeholder()}</Text>
      <input
        accept={(typeof props.accept === 'string') ? props.accept : ''}
        onChange={handleChange}
        type='file'
        value={(typeof props.value === 'string') ? props.value : ''}
      />
    </div>
  );
}
