// Eldhose John 20240119 @SumUps.com

export default function Style(props) {
  const font = 'font-family:' + ((
    typeof props === 'object' && typeof props.font === 'string' &&
    props.font !== '' && props.font !== 'Default'
  ) ? props.font :
    `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif`
  ) + ';';
  const theme = (
    typeof props === 'object' && typeof props.theme === 'string' &&
    props.theme !== ''
  ) ? props.theme :
  '--background-loading-active:#9ca3af;--background-loading-inactive:#e5e7eb;';

  const style = document.createElement('style')
  style.type = 'text/css';
  style.textContent = 'body{' + font + '} :root{' + theme + '}';
  document.head.appendChild(style)
}
