// Eldhose John 20240123 @SumUps.com
import { useEffect, useRef } from 'react';
import Text from './Text';

export default function Input(props) {
  const getType = () => (
    (typeof props.type === 'string') ? props.type : 'text'
  );
  const classes = () => (
    'Input ' + (
      (typeof props.error === 'boolean' && props.error) ?
        'InputError' : 'InputOk'
    )
  );
  const handleBlur = () => {
//    setType(getType());
    if(typeof props.onBlur === 'function') props.onBlur();
  }
  const handleChange = (e) => {
    if(typeof props.onChange === 'function') props.onChange(e);
  }
  const handleFocus = () => {
//    if(type === 'password') setType('text');
    if(typeof props.onFocus === 'function') props.onFocus();
  }
  const maxlength = () => {
    if(typeof props.maxlength !== 'undefined')
      return props.maxlength;
    if(typeof props.maxLength !== 'undefined')
      return props.maxLength;
    return 100;
  }
  const placeholder = () => (
    (typeof props.placeholder === 'string') ?  props.placeholder : ''
  );
  const posticon = () => (
    (typeof props.posticon === 'undefined') ? '' : props.posticon
  );
  const preicon = () => (
    (typeof props.preicon === 'undefined') ? '' : (<span>{props.preicon}</span>)
  );
  const refFocus = useRef(null);
  useEffect(() => {
    if(
      typeof props.focus === 'boolean' && props.focus === true &&
      refFocus.current
    )
      refFocus.current.focus();
  }, [props.focus]);
  return (
    <div>
      <Text className='Small'>{placeholder()}</Text>
      <div className={classes()}>
        {preicon()}
        <input
          maxLength={maxlength()}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder={placeholder()}
          ref={refFocus}
          size={(typeof props.size === 'number') ? props.size : 29}
          type={getType()}
          value={(typeof props.value === 'string') ? props.value : ''}
        />
        {posticon()}
      </div>
    </div>
  );
}
