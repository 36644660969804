// Eldhose John 20241222 @SumUps.com

export default function List(props) {
  const cn = () => {
    if(typeof props.type === 'string') {
      if(props.type === 'summary')
        return 'ImageSummary';
    }
    return '';
  }
  const src = () => ((typeof props.src === 'string') ? props.src : '');
  return (
    <img
      alt=''
      className={cn()}
      src={'/v1/api/images/?' + src()}
    />
  );
}
