// Eldhose John 20240320 @SumUps.com
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

export default function Select(props) {
  const [display, setDisplay] = useState('none');
  const getDisabled = () => (
    (typeof props.disabled === 'boolean') ? props.disabled : false
  );
  const handleClose = (e) => {
    handleSelect(false, e);
  }
  const handleChange = (key) => {
    if(typeof props.onChange === 'function')
      props.onChange(key);
    handleSelect(false, null);
  }
  const handleClick = (e) => {
    if(typeof props.onClick === 'function')
      props.onClick(e);
  }
  const handleSelect = (on, e) => {
    const disp = (on && display === 'none') ? 'block' : 'none';
    setDisplay(disp);
    if(disp === 'block')
      setTimeout(() => document.addEventListener('click', handleClose), 100);
    else
      document.removeEventListener('click', handleClose);
  }
  const option = (key) => {
    if(typeof props.options[key].option !== 'undefined')
      return props.options[key].option;
    return key;
  }
  const options = () => {
    return (
      <div className='EditorSelectMenu' style={{display:display}} tabIndex='-1'>
        {Object.keys(props.options).map((key, i) => ((
            typeof props.selected === 'undefined' || props.selected !== key
          ) ? (
            <div key={i} onClick={() => handleChange(key)}>
              {option(key)}
            </div>
          ) : ''
        ))}
      </div>
    );
  }
  const select = () => {
    if(
      typeof props.selected !== 'undefined' &&
      typeof props.options[props.selected] !== 'undefined'
    ) {
      if(typeof props.options[props.selected].select !== 'undefined')
        return props.options[props.selected].select;
      return props.selected;
    }
    return ;
  }
  return (
    <div className='EditorSelect'>
      <button
        disabled={getDisabled()}
        onClick={handleClick}
      >
        {select()}
      </button>
      <button
        disabled={getDisabled()}
        onClick={(e) => handleSelect(true, e)}
      >
        <FontAwesomeIcon icon={
          display === 'block' ? faCaretUp : faCaretDown
        } />
      </button>
      {options()}
    </div>
  );
}
