// John Eldhose 20240114 @SumUps.com
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faNewspaper, faPowerOff, faUser } from '@fortawesome/free-solid-svg-icons'
import { globalFunctionUse, globalPost } from '../lib/Global';
import { locationLoad, locationSet } from '../lib/Location';
import Button from '../elem/Button';
import Text from '../elem/Text';

export default function ProfileMenu(props) {
  const Message = globalFunctionUse('Message');
  const [display, setDisplay] = useState('none');
  const handleClose = (e) => {
    handleClick(false, e);
  }
  const handleClick = (on, e) => {
    if(props.user === '') {
      locationSet({page: '/signin', parms: {}});
    }
    else {
      const disp = (on && display === 'none') ? 'block' : 'none';
      setDisplay(disp);
      if(disp === 'block')
        setTimeout(() => document.addEventListener('click', handleClose), 100);
      else
        document.removeEventListener('click', handleClose);
    }
  }
  const handleReport = (e) => {
    e.preventDefault();
    locationSet({page: '/report', parms: {nid: 'new'}});
  }
  const handleProfile = (e) => {
    e.preventDefault();
    locationSet({page: '/profile', parms: {}});
  }
  const handleSettings = (e) => {
    e.preventDefault();
    locationSet({page: '/settings', parms: {}});
  }
  const handleSignout = (e) => {
    e.preventDefault();
    globalPost({mod: 'signout'}, (res) => locationLoad('/'));
  }
  const user = () => (
    (props.user === '') ? Message(
      'ada2e9e96fa9ce85430225b412068b5b62b79800', 'Sign in'
    ) : props.user
  );
  return (
    <nav className='ProfileMenu'>
      <Button onClick={(e) => handleClick(true, e)}>
        <FontAwesomeIcon icon={faUser} /> &nbsp;<Text>{user()}</Text>
      </Button>
      <div style={{display: display}} tabIndex='-1'>
        <a href='/report?nid=new' onClick={handleReport}>
          <FontAwesomeIcon icon={faNewspaper} /><Text>{Message(
            '6eb28576029bf24092506c2dc0d504dae2f88423', 'Report News'
          )}</Text>
        </a>
        <a href='/profile' onClick={handleProfile}>
          <FontAwesomeIcon icon={faUser} /><Text>{Message(
            '21c7fe92b8e2c31b06b3e871a2c2e9c168813a64', 'Your Profile'
          )}</Text>
        </a>
        <a href='/settings' onClick={handleSettings}>
          <FontAwesomeIcon icon={faGear} /><Text>{Message(
            'c7f73bb54d928922c3838bb789ee9fb8a5b1eb37', 'Settings'
          )}</Text>
        </a>
        <a href='/signout' onClick={handleSignout}>
          <FontAwesomeIcon icon={faPowerOff} /><Text>{Message(
            'dc1649a16c1496db3e4073be6a73faf5121aeae7', 'Sign out'
          )}</Text>
        </a>
      </div>
    </nav>
  );
}
