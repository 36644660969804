// Eldhose John 20240201 @SumUps.com
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLockOpen } from '@fortawesome/free-solid-svg-icons'
import { globalFunctionUse, globalPost } from '../lib/Global';
import { locationLoad, locationSet } from '../lib/Location';
import Button from '../elem/Button';
import Email from '../user/Email';
import OTP from '../elem/OTP';
import Password from '../user/Password';
import Text from '../elem/Text';

export default function Recover() {
  const Message = globalFunctionUse('Message');
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState('');
  const [emailErr, setEmailErr] = useState(false);
  const [otp, setOtp] = useState('');
  const [otpOk, setOtpOk] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordErr, setPasswordErr] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const handleClick = () => {
    const Notify = globalFunctionUse('Notify');
    setDisabled(true);
    if(transactionId === '') {
      globalPost({mod: 'recover', email: email}, (res) => {
        if(
          typeof res.transactionId === 'string' && res.transactionId !== '' &&
          res.transactionId !== '03fe38d5c84cf9296ed8b1481a4b9fe13a5c1ef5'
        )
          setTransactionId(res.transactionId);
        else
          Notify({
            message: Message(
              '03fe38d5c84cf9296ed8b1481a4b9fe13a5c1ef5',
              'Email address is not found'
            ),
            type: 'Warn'
          });
        setDisabled(false);
      });
    }
    else if(otpOk === false) {
      globalPost({
        mod: 'recover', email: email, otp: otp, transactionId: transactionId
      }, (res) => {
        if(
          typeof res.transactionId === 'string' &&
          res.transactionId === transactionId
        )
          setOtpOk(true);
        else
          Notify({
            message: Message(
              '2db8daa67b14f085b8dc6e7d26188ebe618b2a08',
              'Authentication failed'
            ),
            type: 'Err'
          });
        setDisabled(false);
      });
    }
    else {
      globalPost({
        mod: 'recover', email: email, otp: otp, password: password,
        transactionId: transactionId
      }, (res) => {
        if(
          typeof res.transactionId === 'string' &&
          res.transactionId === transactionId
        )
          locationLoad('/signin');
        else
          Notify({
            message: Message(
              '2db8daa67b14f085b8dc6e7d26188ebe618b2a08',
              'Authentication failed'
            ),
            type: 'Err'
          });
        setDisabled(false);
      });
    }
  }
  const body = () => {
    if(email === '' || transactionId === '')
      return (
        <Email
          email={email}
          error={emailErr}
          focus={true}
          setEmail={setEmail}
          setError={setEmailErr}
        />
      );
    else if(otpOk === false)
      return (
        <OTP
          onChange={setOtp}
          placeholder={Message(
            '02551d1fff697352e1196f08d1e7925be68ccd81',
            'Enter the code sent to the email'
          )}
          value={otp}
        />
      );
    else
      return (
        <Password
          error={passwordErr}
          focus={true}
          password={password}
          setPassword={setPassword}
          setError={setPasswordErr}
        />
      );
  }
  const handleDisabled = () => (
    disabled || email === '' || emailErr ||
    (transactionId !== '' && otpOk === false && otp.length !== 7) ||
    (transactionId !== '' && otpOk && otp.length === 7 && passwordErr)
  );
  const handleSignup = (e) => {
    e.preventDefault();
    locationSet({page: '/signup', parms: {}});
  }
  return (
    <nav className='Recover'>
      <Text className='Title'>{Message(
        '387b9ee425a5271696cbf497985e04a2ee157f5e', 'Recover your lost account'
      )}</Text>
      {body()}
      <a
        className='AButton'
        href='/signup'
        onClick={handleSignup}
        tabIndex={-1}
      >
        {Message(
          '4c4773e451c399785b926de45d2e3aa7d3c68030',
          'Sign up for a new account'
        )}
      </a>
      <Button disabled={handleDisabled()} onClick={handleClick}>
        <FontAwesomeIcon icon={faLockOpen} />&nbsp;{Message(
          '4addbf16731014acdf0d8a16840ab8a8ab4ea995', 'Recover'
        )}
      </Button>
    </nav>
  );
}
