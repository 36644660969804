// Eldhose John 20240303 @SumUps.com
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { globalFunctionUse } from '../lib/Global';
import Input from '../elem/Input';

export default function Email(props) {
  const Message = globalFunctionUse('Message');
  const handleError = (eml) => {
    if(
      ((typeof props.emptyValid === 'boolean') ? props.emptyValid : false) &&
      eml === ''
    )
      props.setError(false);
    else
      props.setError(!(/^[^@]+@[^.]+\.[^$]{2,}$/.test(eml)));
  }
  const handleBlur = () => (handleError(props.email));
  const handleChange = (e) => {
    props.setEmail(e.target.value.toLowerCase());
    handleError(e.target.value.toLowerCase());
  }
  const placeholder = () => (
    (typeof props.placeholder === 'string') ? props.placeholder : Message(
      'c94d3175a6560565410511df2cebab9cda96027e', 'Email address'
    )
  )
  return (
    <Input
      error={props.error}
      focus={typeof props.focus === 'boolean' && props.focus === true}
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder={placeholder()}
      preicon={<FontAwesomeIcon icon={faEnvelope} />}
      value={props.email}
    />
  );
}
