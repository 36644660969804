// Eldhose John 20240327 @SumUps.com
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlignCenter, faAlignJustify, faAlignLeft, faAlignRight, faBold, faIndent, faItalic, faList, faListOl, faOutdent, faStrikethrough, faSubscript, faSuperscript, faUnderline } from '@fortawesome/free-solid-svg-icons'
//import { faBold, faIndent, faItalic, faLink, faList, faListOl, faOutdent, faPaintbrush, faPaintRoller, faStrikethrough, faUnderline } from '@fortawesome/free-solid-svg-icons'
//import Colors from './Colors';
//import Input from './Input';
import Select from './Select';
import Text from '../elem/Text';

const command = {
  a: 'createLink', b: 'bold', blockquote: 'indent',
  i: 'italic',
  justifyFull: 'justifyFull', justifyLeft: 'justifyLeft',
  justifyCenter: 'justifyCenter', justifyRight: 'justifyRight',
  listOrdered: 'insertOrderedList', listUnordered: 'insertUnorderedList',
  outdent: 'outdent', removeFormat: 'removeFormat',
  s: 'strikeThrough', sub: 'subscript',
  sup: 'superscript', u: 'underline'
};
export default function Toolbar(props) {
  const [align, setAlign] = useState('justifyFull');
  //const [background, setBackground] = useState('var(--background-light)');
  const [disabled, setDisabled] = useState(false);
  const [list, setList] = useState('listOrdered');
  //const [foreground, setForeground] = useState('var(--foreground-light)');
  const [text, setText] = useState('normal');
  const focusEdit = () => {
    if(
      typeof props.editRef === 'object' &&
      typeof props.editRef.current === 'object' &&
      typeof props.editRef.current.focus === 'function'
    )
      props.editRef.current.focus();
  }
  const focusin = (e) => (setDisabled(false));
  const focusout = (e) => ((
    typeof props.editorRef.current.contains === 'function' &&
    !props.editorRef.current.contains(e.relatedTarget)
  ) ? setDisabled(true) : true);
/*
  const handleChangeBackground = (color) => {
    setBackground(color);
    handleClick('backColor', null, color);
  }
  const handleChangeForeground = (color) => {
    setForeground(color);
    handleClick('foreColor', null, color);
  }
*/
  const handleChangeAlign = (key) => {
    setAlign(key);
    focusEdit();
  }
  const handleChangeList = (key) => {
    setList(key);
    focusEdit();
  }
  const handleChangeText = (key) => {
    setText(key);
    focusEdit();
  }
  const handleClick = (ele, e, val) => {
    if([].includes(ele)) {
      const sel = window.getSelection();
      if(
        typeof sel === 'object' &&
        typeof sel.rangeCount === 'number' && sel.rangeCount > 0
      ) {
        for(let i = 0; i < sel.rangeCount; i++) {
          let elem = document.createElement(ele);
          let range = sel.getRangeAt(i);
          range.surroundContents(elem);
        }
      }
    }
    else if(['createLink', 'formatBlock'].includes(ele))
      document.execCommand(ele, null, val);
    else
      document.execCommand(command[ele]);
    focusEdit();
  }
  const handleClickText = (e) => {
    if(text === 'title')
      handleClick('formatBlock', e, '<h1>');
    else if(text === 'subtitle')
      handleClick('formatBlock', e, '<h3>');
    else if(text === 'small')
      handleClick('formatBlock', e, '<h5>');
    else 
      handleClick('formatBlock', e, '<div>');
      //handleClick('removeFormat');
  }
  useEffect(() => {
    if(
      typeof props.editorRef === 'object' &&
      typeof props.editorRef.current === 'object'
    ) {
      props.editorRef.current.addEventListener('focusin', focusin);
      props.editorRef.current.addEventListener('focusout', focusout);
    }
    return () => {
      if(
        typeof props.editorRef === 'object' &&
        props.editorRef.current !== null &&
        typeof props.editorRef.current === 'object' &&
        typeof props.editorRef.current.removeEventListener === 'function'
      ) {
        props.editorRef.current.removeEventListener('focusin', focusin);
        props.editorRef.current.removeEventListener('focusout', focusout);
      }
    }
  });
  return (
    <nav className='EditorToolbar'>
      <div>
        <Select
          disabled={disabled}
          onChange={handleChangeText}
          onClick={handleClickText}
          options={{
            title: {
              option: <Text className='Title'>Title</Text>,
              select: 'Title'
            },
            subtitle: {
              option: <Text className='Subtitle'>Subtitle</Text>,
              select: 'Subtitle'
            },
            normal: {
              option: <Text className='Normal'>Normal</Text>,
              select: 'Normal'
            },
            small: {
              option: <Text className='Small'>Small</Text>,
              select: 'Small'
            }
          }}
          selected={text}
        />
      </div>
      <div>
        <button
          disabled={disabled}
          onClick={(e) => handleClick('b', e)}
        >
          <FontAwesomeIcon icon={faBold} />
        </button>
      </div>
      <div>
        <button
          disabled={disabled}
          onClick={(e) => handleClick('i', e)}
        >
          <FontAwesomeIcon icon={faItalic} />
        </button>
      </div>
      <div>
        <button
          disabled={disabled}
          onClick={(e) => handleClick('u', e)}
        >
          <FontAwesomeIcon icon={faUnderline} />
        </button>
      </div>
      <div>
        <button
          disabled={disabled}
          onClick={(e) => handleClick('s', e)}
        >
          <FontAwesomeIcon icon={faStrikethrough} />
        </button>
      </div>
{/*
      <div>
        <Input
          disabled={disabled}
          icon={<FontAwesomeIcon icon={faLink} />}
          onChange={(val) => handleClick('createLink', null, val)}
          placeholder='URL'
        />
      </div>
*/}
      <div>
        <button
          disabled={disabled}
          onClick={(e) => handleClick('sub', e)}
        >
          <FontAwesomeIcon icon={faSubscript} />
        </button>
      </div>
      <div>
        <button
          disabled={disabled}
          onClick={(e) => handleClick('sup', e)}
        >
          <FontAwesomeIcon icon={faSuperscript} />
        </button>
      </div>
{/*
      <div>
        <Colors
          disabled={disabled}
          icon=<FontAwesomeIcon icon={faPaintbrush} />
          onChange={handleChangeForeground}
          value={foreground}
        />
      </div>
      <div>
        <Colors
          disabled={disabled}
          icon=<FontAwesomeIcon icon={faPaintRoller} />
          onChange={handleChangeBackground}
          value={background}
        />
      </div>
*/}
      <div>
        <Select
          disabled={disabled}
          onChange={handleChangeAlign}
          onClick={(e) => handleClick(align, e)}
          options={{
            justifyFull: {
              option: <FontAwesomeIcon icon={faAlignJustify} />,
              select: <FontAwesomeIcon icon={faAlignJustify} />
            },
            justifyLeft: {
              option: <FontAwesomeIcon icon={faAlignLeft} />,
              select: <FontAwesomeIcon icon={faAlignLeft} />
            },
            justifyCenter: {
              option: <FontAwesomeIcon icon={faAlignCenter} />,
              select: <FontAwesomeIcon icon={faAlignCenter} />
            },
            justifyRight: {
              option: <FontAwesomeIcon icon={faAlignRight} />,
              select: <FontAwesomeIcon icon={faAlignRight} />
            }
          }}
          selected={align}
        />
      </div>
      <div>
        <button
          disabled={disabled}
          onClick={(e) => handleClick('blockquote', e)}
        >
          <FontAwesomeIcon icon={faIndent} />
        </button>
      </div>
      <div>
        <button
          disabled={disabled}
          onClick={(e) => handleClick('outdent', e)}
        >
          <FontAwesomeIcon icon={faOutdent} />
        </button>
      </div>
      <div>
        <Select
          disabled={disabled}
          onChange={handleChangeList}
          onClick={(e) => handleClick(list, e)}
          options={{
            listOrdered: {
              option: <FontAwesomeIcon icon={faListOl} />,
              select: <FontAwesomeIcon icon={faListOl} />
            },
            listUnordered: {
              option: <FontAwesomeIcon icon={faList} />,
              select: <FontAwesomeIcon icon={faList} />
            }
          }}
          selected={list}
        />
      </div>
    </nav>
  );
}
