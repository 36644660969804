// Eldhose John 20240122 @SumUps.com
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { globalFunctionUse, globalPost } from '../lib/Global';
import Button from '../elem/Button';
import Email from '../user/Email';
import Password from '../user/Password';
import Text from '../elem/Text';
import User from '../user/User';

export default function Profile() {
  const Message = globalFunctionUse('Message');
  const Notify = globalFunctionUse('Notify');
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState('');
  const [emailErr, setEmailErr] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordErr, setPasswordErr] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [user, setUser] = useState('');
  const [userErr, setUserErr] = useState('');
  const body = () => (
    <>
      <Email
        email={email}
        emptyValid={true}
        error={emailErr}
        placeholder={Message(
          '321c2a947d73eed9d19da5b24e94971b96aa18f5', 'New email address'
        )}
        setEmail={setEmail}
        setError={setEmailErr}
      />
      <Password
        emptyValid={true}
        error={passwordErr}
        password={password}
        placeholder={Message(
          'd850ee188c7c55b64bc3624534de5c5051a57dc6', 'New password'
        )}
        setPassword={setPassword}
        setError={setPasswordErr}
      />
      <User
        emptyValid={true}
        error={userErr}
        placeholder={Message(
          '0c5afdf005da5675525ca1c733a2801df49b9b9c', 'New user name'
        )}
        setUser={setUser}
        setError={setUserErr}
        user={user}
      />
    </>
  );
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordErr, setCurrentPasswordErr] = useState('');
  const currentBody = () => (
    <Password
      error={currentPasswordErr}
      password={currentPassword}
      placeholder={Message(
        '19dff4dad0a7214ece14624f8c4c9fb206a1cfdd', 'Current password'
      )}
      setPassword={setCurrentPassword}
      setError={setCurrentPasswordErr}
    />
  );
  const handleClick = () => {
    if(transactionId === '') {
      setDisabled(true);
      globalPost({mod: 'profile', email: email, password: password, user: user},
      (res) => {
        if(typeof res.transactionId === 'string' && res.transactionId !== '') {
          if(res.transactionId === 'c6de9581ca3b4c359074e2ea9c44c43c7d1dcbde')
            Notify({
              message: Message(
                'c6de9581ca3b4c359074e2ea9c44c43c7d1dcbde',
                'User ID is already in use. Please select a different name for User'
              ),
              type: 'Warn'
            });
          else if(
            res.transactionId === '474d083144758ae02fc3428e87962ed87b18774b'
          )
            Notify({
              message: Message(
                '474d083144758ae02fc3428e87962ed87b18774b',
                'An account already exist with that email address'
              ),
              type: 'Warn'
            });
          else
            setTransactionId(res.transactionId);
        }
        setDisabled(false);
      });
    }
    else {
      setDisabled(true);
      globalPost({
        mod: 'profile', currentPassword: currentPassword, email: email,
        password: password, transactionId: transactionId, user: user
      }, (res) => {
        if(
          typeof res.transactionId === 'string' &&
          res.transactionId === transactionId
        )
          Notify({
            message: Message(
              '183f8bad27e8a4b6fb4830cea2cca076f240d1e0',
              'Profile updated successfully'
            ),
            type: 'Ok'
          });
        else {
          Notify({
            message: Message(
              '6a85beb146880f2c93dcfd069572c1ea146ee172',
              'Profile update failed'
            ),
            type: 'Err'
          });
          setDisabled(false);
        }
      });
    }
  }
  const handleDisabled = () => (
    disabled ||
    (email === '' && password === '' && user === '') ||
    emailErr || passwordErr || userErr ||
    (transactionId !== '' && (currentPassword === '' || currentPasswordErr))
  );
  return (
    <nav className='Profile'>
      <Text className='Title'>{Message(
        '341a1900aefd2058e08d8516e495ab8eac74a1fe', 'Manage your profile'
      )}</Text>
      {(transactionId === '') ? body() : currentBody()}
      <Text className='Caption'>
        {Message(
          '9fb5bcffe89a2d1d5b31fd8cde5625f29515f65f',
          'Keep the fields blank to not change them'
        )}
      </Text>
      <Button disabled={handleDisabled()} onClick={handleClick}>
        <FontAwesomeIcon icon={faFloppyDisk} />&nbsp;
        {Message('fb91e24fa52d8d2b32937bf04d843f730319a902', 'Update')}
      </Button>
    </nav>
  );
}
