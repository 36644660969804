// John Eldhose 20240114 @SumUps.com
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import '../css/News.css';
import { globalFunctionUse } from '../lib/Global';
import List from '../news/List';
import { locationGet } from '../lib/Location';
import View from '../news/View';
import Text from '../elem/Text';

export default function News(props) {
  const Refresh = globalFunctionUse('Refresh');
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(!refresh) setRefresh(true);
    }  , 300000);
    return (() => {
      clearTimeout(timeoutId);
    });
  }, [refresh, setRefresh]);
  const precedingButton = () => {
    if(props.news.length > 0 && (props.news.length % 20) === 0 && (
      typeof props.data.lastNewsLength === 'undefined' ||
      props.data.lastNewsLength === 20
    ))
      return (
        <div className='NewsList'>
          <button
            onClick={() => {
              const obj = locationGet();
              obj.parms.preceding = props.news[props.news.length - 1].nid;
              Refresh(obj);
            }}
          >
            <Text>Load more news reports . . .</Text>
          </button>
        </div>
      );
    else
      return '';
  }
  const refreshButton = () => {
    if(typeof props.data.lastNewsTime === 'number' && (
      refresh || typeof props.data.lastNewsTime !== 'number' ||
      (props.data.lastNewsTime + 70000) < Date.now()
    ))
      return (
        <div className='NewsList'>
          <button
            onClick={() => {
              const url = locationGet();
              Refresh(url);
              setRefresh(false);
            }}
          >
            <Text>Check for new news reports . . .</Text>
          </button>
        </div>
      );
    return '';
  }
  if(typeof props.url.parms.nid === 'string' && props.url.parms.nid !== '') {
    if(
      typeof props.data.lastUrl === 'object' &&
      typeof props.data.lastUrl.page === 'string' &&
      ['/', '/report'].includes(props.data.lastUrl.page)
    ) {
      return (
        <nav className='News'>
          <button onClick={(e) => window.history.back()}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
          {
            (typeof props.data.view === 'object') ?
              <View data={props.data.view} /> : <div />
          }
        </nav>
      );
    }
    else
      return (
        <nav className='News'>
          {
            (typeof props.data.view === 'object') ?
              <View data={props.data.view} /> : <div />
          }
        </nav>
      );
  }
  else {
    return (
      <nav className='News News-md'>
        <div>
          {refreshButton()}
          <List news={props.news} user={props.user} />
          {precedingButton()}
        </div>
      </nav>
    );
  }
}
