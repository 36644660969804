// Eldhose John 20240123 @SumUps.com
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk, faFont, faLanguage, faPalette } from '@fortawesome/free-solid-svg-icons'
import { globalFunctionUse, globalPost } from '../lib/Global';
import { locationLoad } from '../lib/Location';
import Button from '../elem/Button';
import Dropdown from '../elem/Dropdown';
import Input from '../elem/Input';
import Text from '../elem/Text';

export default function Settings(props) {
  const Message = globalFunctionUse('Message');
  const Notify = globalFunctionUse('Notify');
  const getSelected = (type) => {
    if(
      typeof props.data.selected === 'object' &&
      typeof props.data.selected[type] === 'string'
    )
      return props.data.selected[type];
    else
      return '';
  }
  const [font, setFont] = useState(getSelected('font'));
  const [language, setLanguage] = useState(getSelected('language'));
  const [theme, setTheme] = useState(getSelected('theme'));
  const [updates, setUpdates] = useState(0);
  const handleChange = (cust, e) => {
    props.data.custom[cust] = e.target.value;
    setUpdates(updates + 1);
  }
  const handleClick = () => {
    globalPost({mod: 'settings', settings: {
      custom: ((theme === 'Custom') ? props.data.custom : null),
      font: font, language: language, theme: theme
    }}, (res) => {
      if(
        typeof res.transactionId === 'string' &&
        res.transactionId === 'cc702250293fb0a1714e080e1a756eb4a5b480df'
      )
        locationLoad('/');
      else
        Notify({
          message: Message(
            '4de04cd91a3d954b02c7397e263feddd8519c483', 'Update failed'
          ),
          type: 'Err'
        });
    });
  }
  const handleDisabled = () => (
    font === getSelected('font') &&
    language === getSelected('language') && theme === getSelected('theme') &&
    theme !== 'Custom'
  );
  const items = (type) => {
    if(typeof props.data.settings === 'undefined')
      return {};
    else if(typeof props.data.settings[type] === 'object')
      return props.data.settings[type];
    else
      return {};
  }
  const customTheme = () => {
    if(theme !== 'Custom' || typeof props.data.custom !== 'object')
      return ;
    return (
      <div>
        {Object.keys(props.data.custom).map((cust, i) => (
          <Input 
            key={i}
            onChange={(e) => handleChange(cust, e)}
            placeholder={cust}
            value={props.data.custom[cust]}
          />
        ))}
      </div>
    );
  }
  return (
    <nav className='Settings'>
      <Text className='Title'>
        {Message('11750050c483729bc543d62dc86fae953b2b8653', 'Manage settings')}
      </Text>
      <Dropdown
        items={items('font')}
        onSelect={(key) => setFont(key)}
        placeholder={Message(
          'b97c4d4cddf23ec47395af460f9e28df2a0f9b04', 'Font'
        )}
        preicon={<FontAwesomeIcon icon={faFont} />}
        value={font}
      />
      <Dropdown
        items={items('language')}
        onSelect={(key) => setLanguage(key)}
        placeholder={Message(
          '89b86ab0e66f527166d98df92ddbcf5416ed58f6', 'Language'
        )}
        preicon={<FontAwesomeIcon icon={faLanguage} />}
        value={language}
      />
      <Dropdown
        items={items('theme')}
        onSelect={(key) => setTheme(key)}
        placeholder={Message(
          'a797e30923ac1be590300ce6b08e63b4e6dc6688', 'Theme'
        )}
        preicon={<FontAwesomeIcon icon={faPalette} />}
        value={theme}
      />
      {customTheme()}
      <Button disabled={handleDisabled()} onClick={handleClick}>
        <FontAwesomeIcon icon={faFloppyDisk} /> &nbsp;<Text>{Message(
          'efc007a393f66cdb14d57d385822a3d9e36ef873', 'Save'
        )}</Text>
      </Button>
    </nav>
  );
}
