// Eldhose John 20240128 @SumUps.com
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFlag, faStar, faStarHalfStroke , faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faFlag as faFlagSolid, faStar as faStarSolid, faThumbsDown as faThumbsDownSolid, faThumbsUp as faThumbsUpSolid } from '@fortawesome/free-solid-svg-icons';
import Image from '../elem/Image';
import { locationSet } from '../lib/Location';
import Text from '../elem/Text';

export default function List(props) {
  const date = (dt, fmt) => {
    if(fmt === 'd')
      return(dt.substring(0, 10));
    else {
      let d = new Date(dt);
      return(d.toDateString() + ' ' + d.toLocaleTimeString());
    }
  }
  const handleClick = (type, e, news) => {
    e.preventDefault();
    if(type === 'edit')
      locationSet({page: '/report', parms: {nid: news.nid}});
    else if(type === 'flag')
      locationSet({page: '/', parms: {flag: 'none'}});
    else if(type === 'reported')
      locationSet({page: '/', parms: {reported: date(news.reported, 'd')}});
    else if(type === 'reporter')
      locationSet({page: '/', parms: {reporter: news.reporter}});
    else if(type === 'seen')
      locationSet({page: '/', parms: {seen: false}});
    else if(type === 'star')
      locationSet({page: '/', parms: {star: '+2.5'}});
    else if(type === 'tag')
      locationSet({page: '/', parms: {tag: news.tag}});
    else if(type === 'view')
      locationSet({
        page: '/', parms: {nid: news.nid, title: encodeURIComponent(news.title)}
      });
  }
  const editNews = (news) => {
    if(news.reporter === props.user)
      return (
        <a
          href={'/report?n=' + news.nid}
          onClick={(e) => handleClick('edit', e, news)}
        >
          <FontAwesomeIcon icon={faEdit} />
        </a>
      );
    else
      return '';
  }
  const getFlag = (news) => {
    return (
      <a href='/?flag=none' onClick={(e) => handleClick('flag', e, news)}>
        {(
          typeof news.ranking === 'object' &&
          typeof news.ranking.flag === 'string' &&
          news.ranking.flag !== '' && news.ranking.flag !== 'none'
        ) ?
          <FontAwesomeIcon icon={faFlagSolid} className='NewsListFlag' /> :
          <FontAwesomeIcon icon={faFlag} />
        }
      </a>
    );
  }
  const getStar = (news) => {
    const rating = (
      typeof news.ranking === 'object' &&
      typeof news.ranking.rating === 'number'
    ) ? news.ranking.rating : 0;
    if(rating < 2.5)
      return (
        <a href='/star=+2.5' onClick={(e) => handleClick('star', e, news)}>
          <FontAwesomeIcon icon={faStar} />
        </a>
      );
    else if(rating > 3.5)
      return (
        <a href='/star=+2.5' onClick={(e) => handleClick('star', e, news)}>
          <FontAwesomeIcon icon={faStarSolid} className='NewsListStar' />
        </a>
      );
    else
      return (
        <a href='/star=+2.5' onClick={(e) => handleClick('star', e, news)}>
          <FontAwesomeIcon icon={faStarHalfStroke} className='NewsListStar' />
        </a>
      );
  }
  const getThumbsUp = (news) => {
    const ranked = (
      typeof news.ranking === 'object' &&
      typeof news.ranking.ranked === 'number'
    ) ? news.ranking.ranked : 0;
    if(ranked > 0)
      return (
        <a href='/seen=false' onClick={(e) => handleClick('seen', e, news)}>
          <FontAwesomeIcon
            icon={faThumbsUpSolid}
            className='NewsListThumbsUp'
          />
        </a>
      );
    else if(ranked < 0)
      return (
        <a href='/seen=false' onClick={(e) => handleClick('seen', e, news)}>
          <FontAwesomeIcon
            icon={faThumbsDownSolid}
            className='NewsListThumbsDown'
          />
        </a>
      );
    else
      return (
        <a href='/seen=false' onClick={(e) => handleClick('seen', e, news)}>
          <FontAwesomeIcon icon={faThumbsUp} />
        </a>
      );
  }
  return (
    <>
      {props.news.map((news, i) => {
        return (
          <div className='NewsList' key={i}>
            <a
              href={'/?nid=' + news.nid + '&title=' + encodeURIComponent(news.title)}
              onClick={(e) => handleClick('view', e, news)}
            >
              <div>
                <Text className='Title'><b>{news.title}</b></Text>
                <Image src={news.image} type='summary' />
              </div>
              <div><Text>{news.summary}</Text></div>
            </a>
            <div>
              <a
                href={'/?reported=' + date(news.reported, 'd')}
                onClick={(e) => handleClick('reported', e, news)}
              >
                {date(news.reported, 'dt')}
              </a>
              <a
                href={'/?tag=' + news.tag}
                onClick={(e) => handleClick('tag', e, news)}
              >
                {news.tag}
              </a>
              <div>
                {getFlag(news)}
                {getStar(news)}
                {getThumbsUp(news)}
              </div>
              <div>
                <a
                  href={'/?reporter=' + news.reporter}
                  onClick={(e) => handleClick('reporter', e, news)}
                >
                  {news.reporter}
                </a>
                {editNews(news)}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
