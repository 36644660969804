// Eldhose John 20241226 @SumUps.com
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faPenToSquare, faShare } from '@fortawesome/free-solid-svg-icons';
import Button from '../elem/Button';
import Email from '../user/Email';
import { globalFunctionUse, globalPost } from '../lib/Global';
import Input from '../elem/Input';
import Text from '../elem/Text';
import Textarea from '../elem/Textarea';

export default function Feedback(props) {
  const Message = globalFunctionUse('Message');
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState('');
  const [emailErr, setEmailErr] = useState(false);
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState('');
  if(page !== props.url.page) {
    window.scrollTo(0, 0);
    setPage(props.url.page);
  }
  const [subject, setSubject] = useState('');
  const handleChangeMessage = (e) => (setMessage(e.target.value));
  const handleChangeName = (e) => (setName(e.target.value));
  const handleChangeSubject = (e) => (setSubject(e.target.value));
  const handleClickSend = () => {
    setDisabled(true);
    const Notify = globalFunctionUse('Notify');
    globalPost({
      mod: 'feedback', act: props.url.page, name: name, email: email,
      subject: subject, message: message
    }, (res) => {
      if(
        typeof res.transactionId === 'string' &&
        res.transactionId !== 'd9746b107b0cb02da989132dbec5bf3187061cb4'
      ) {
        Notify({
          message: Message(
            '28b76449cbd6df2bd4083496facc2a2a2d434781', 'Sent successfully'
          ),
          type: 'Ok'
        });
      }
      else {
        setDisabled(false);
        Notify({
          message: Message(
            'd9746b107b0cb02da989132dbec5bf3187061cb4', 'Sent failed'
          ),
          type: 'Warn'
        });
      }
    });
  }
  const handleDisabled = () => (
    disabled || email === '' || name === '' || emailErr || message === '' ||
    subject === ''
  );
  const title = () => {
    if(props.url.page === '/contactus')
      return (Message(
        '4832e45812a2724f16a15f9ae87adfc8ae4168cf', 'Contact us'
      ));
    else if(props.url.page === '/featurerequest')
      return (Message(
        '94ddc396170220f85217f31e933130367f8fde81', 'Feature request'
      ));
    else if(props.url.page === '/reportaproblem')
      return (Message(
        '24f3f52de52ef0709de0c798934acad15b619528', 'Report a problem'
      ));
  }
  return (
    <nav className='Feedback'>
      <Text className='Title'>{title()}</Text>
      <Input
        onChange={handleChangeName}
        placeholder={Message(
          '709a23220f2c3d64d1e1d6d18c4d5280f8d82fca', 'Name'
        )}
        preicon={<FontAwesomeIcon icon={faAddressCard} />}
        value={name}
      />
      <Email
        email={email}
        error={emailErr}
        setEmail={setEmail}
        setError={setEmailErr}
      />
      <Input
        onChange={handleChangeSubject}
        placeholder={Message(
          '8d183dbdcea3b29906090bd83fa6fa37923cc8ec', 'Subject'
        )}
        preicon={<FontAwesomeIcon icon={faPenToSquare} />}
        value={subject}
      />
      <Textarea
        onChange={handleChangeMessage}
        placeholder={Message(
          '68f4145fee7dde76afceb910165924ad14cf0d00', 'Message'
        )}
        value={message}
      />
      <Button disabled={handleDisabled()} onClick={handleClickSend}>
        <FontAwesomeIcon icon={faShare} /> &nbsp;<Text>{Message(
          '9bc2575c3930437e80555f78757b783c842e8e66', 'Send'
        )}</Text>
      </Button>
    </nav>
  );
}
