// Eldhose John 20240129 @SumUps.com
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faImage, faNewspaper, faT, faTag } from '@fortawesome/free-solid-svg-icons';
import Button from '../elem/Button';
import Dropdown from '../elem/Dropdown';
import Editor from '../edit/Editor';
import { globalFunctionUse, globalPost } from '../lib/Global';
import Image from '../elem/Image';
import Input from '../elem/Input';
import { locationReplace, locationSet } from '../lib/Location';
import Text from '../elem/Text';
import Upload from '../elem/Upload';
import Textarea from '../elem/Textarea';
import '../edit/Edit.css';

export default function Report(props) {
  const Message = globalFunctionUse('Message');
  const [disabled, setDisabled] = useState(true);
  const getNews = () => {
    let lnews = {details: '', image: '', nid: 'new'};
    if(typeof props.data.news === 'object')
      lnews = Object.assign(lnews, props.data.news);
    if(lnews.nid === '')
      lnews.nid = 'new';
    return lnews;
  }
  const [news, setNews] = useState(getNews());
  const [image, setImage] = useState({link: ''});
  const [imageSelect, setImageSelect] = useState(
    (news.image === '') ? '' : 'current'
  );
  const updateNews = (id, val) => {
    let obj = JSON.parse(JSON.stringify(news));
    obj[id] = val;
    setNews(obj);
    setDisabled(false)
  }
  const handleChangeDetails1 = (e) => (
    updateNews('details', e.target.value.trim())
  );
  const handleChangeDetails2 = (val) => (updateNews('details', val));
  const handleChangeImage = (e) => {
    if(imageSelect === 'upload')
      setImage({link: e.target.value.trim(), file: e.target.files[0]});
    else
      setImage({link: e.target.value.trim()});
}
  const handleChangeSummary = (e) => (updateNews('summary', e.target.value));
  const handleChangeTag = (e) => (updateNews('tag', e.target.value));
  const handleChangeTitle = (e) => (updateNews('title', e.target.value));
  const handleClickSave = () => {
    globalPost({
      mod: 'news', act: ((news.nid === 'new') ? 'add' : 'fix'), nid: news.nid,
      details: news.details, image: news.image, summary: news.summary,
      tag: news.tag, template: news.template, title: news.title
    }, (res) => {
      if(news.nid === 'new')
        locationReplace({page: '/report', parms: {nid: res.nid}});
      locationSet({page: '/', parms: {nid: res.nid}});
    });
  }
  const handleDisabled = () => (
    disabled ||
    typeof news.details === 'undefined' || news.details === '' ||
    typeof news.image === 'undefined' || news.image === '' ||
    typeof news.summary === 'undefined' || news.summary === '' ||
    typeof news.tag === 'undefined' || news.tag === '' ||
    typeof news.title === 'undefined' || news.title === ''
  );
  const handleSaveImage = () => {
    if(imageSelect === 'upload') {
      const reader = new FileReader();
      reader.onload = (e) => {
        globalPost(
          {mod: 'image', act: imageSelect, image: e.target.result}, (res) => {
            if(typeof res.img === 'string') {
              updateNews('image', res.img);
              setImageSelect('current');
            }
          }
        ); 
      };
      reader.readAsDataURL(image.file);
      setImage({link: ''});
    }
    else if(imageSelect === 'url') {
      globalPost({mod: 'image', act: imageSelect, image: image.link}, (res) => {
        if(typeof res.img === 'string') {
          updateNews('image', res.img);
          setImageSelect('current');
        }
      }); 
      setImage({link: ''});
    }
  }
  const handleSelectImage = (key) => {
    setImageSelect(key);
    setImage({link: ''});
    if(
      key === 'current' &&
      (typeof news.image === 'undefined' || news.image === '')
    )
      news.image = '5e7c28cc2bd20371ca4f2487706b01a1.png';
    else if(
      key !== 'current' && (
        typeof news.image === 'string' &&
        news.image === '5e7c28cc2bd20371ca4f2487706b01a1.png'
      )
    )
      news.image = '';
  }
  const handleSelectTag = (key) => (updateNews('tag', key));
  const handleSelectTemplate = (key) => (updateNews('template', key));
  const content = () => {
    if(news.template === 'audio')
      return (
        <Input
          maxlength={1000}
          onChange={handleChangeDetails1}
          placeholder={Message(
            '82599ebcdb6e8f61e15f02c0949801deae898ef1', 'Embedded audio URL'
          )}
          size={40}
          value={news.details}
        />
      );
    else if(news.template === 'video')
      return (
        <Input
          maxlength={1000}
          onChange={handleChangeDetails1}
          placeholder={Message(
            'f7fad471a1d1747c010f53654e2a5564f93b6b85', 'Embedded video URL'
          )}
          size={40}
          value={news.details}
        />
      );
    else if(news.template === 'link')
      return (
        <Input
          maxlength={1000}
          onChange={handleChangeDetails1}
          placeholder={Message(
            'c725b45008054642845dfe69248421862075e7a8', 'URL to the news'
          )}
          size={40}
          value={news.details}
        />
      );
    else if(news.template === 'post')
      return (
        <Editor
          maxlength={2500}
          onChange={handleChangeDetails2}
          placeholder={Message(
            'dc3decbb93847518f1a049dcf49d0d7c6560bcc6', 'Details'
          )}
          style={{height: '15rem', width: '35rem'}}
          value={news.details}
        />
      );
  }
  const contentImage = () => {
    if(imageSelect === 'current')
      return (
        <div>
          <Input
            placeholder={Message(
              'f0e58fc3c9d6a3ce86221a42fc40f2e21b05e6de',
              'Keep current summary image'
            )}
            size={32}
            value={news.image}
          />
        </div>
      );
    else if(imageSelect === 'upload')
      return (
        <div>
          <Upload
            accept={'image/png, image/jpeg'}
            onChange={handleChangeImage}
            placeholder={Message(
              '89cb53e98610239ef63228da8b20aca1d67d71f7', 'Upload summary image'
            )}
            value={image.link}
          />
          <Button
            disabled={image.link === ''}
            onClick={handleSaveImage}
          >
            <Text>
              {Message( 'efc007a393f66cdb14d57d385822a3d9e36ef873', 'Save')}
            </Text>
          </Button>
        </div>
      );
    else if(imageSelect === 'url')
      return (
        <div>
          <Input
            maxlength={500}
            onChange={handleChangeImage}
            placeholder={Message(
              '25265b96459a2c46c42cbf5f070f578336ce200c', 'Summary image URL'
            )}
            size={22}
            value={image.link}
          />
          <Button
            disabled={image.link === ''}
            onClick={handleSaveImage}
          >
            <Text>
              {Message( 'efc007a393f66cdb14d57d385822a3d9e36ef873', 'Save')}
            </Text>
          </Button>
        </div>
      );
  }
  return (
    <nav className='Report'>
      <Text className='Title'>
        {Message('0b24e45cdffcc8ceacd7aa9243ddc9ecb2fe370b', 'Report a news')}
      </Text>
      <Input
        maxLength={250}
        onChange={handleChangeTitle}
        placeholder={Message(
          '768e0c1c69573fb588f61f1308a015c11468e05f', 'Title'
        )}
        preicon={<FontAwesomeIcon icon={faT} />}
        size={50}
        value={news.title}
      />
      <Dropdown
        items={{
          Business: {label: Message(
            'd6663dda5fe9b2524bf91f13a7621ec735f8a155', 'Business'
          )},
          Entertainment: {label: Message(
            '8c520bb472ebeb78af69e21e75630100145102d4', 'Entertainment'
          )},
          Health: {label: Message(
            '3703cd216881d3303a10a898fe54439bf05685b6', 'Health'
          )},
          Science: {label: Message(
            'd2d066371475dee7a2d496ca34a3ab08485cfaf6', 'Science'
          )},
          Sports: {label: Message(
            '2fec45a4cba42f573866f1f9bc5a3e328ea963a7', 'Sports'
          )},
          Technology: {label: Message(
            'd018b082e8a372d9b860417e823c645114337927', 'Technology'
          )},
          'The US': {label: Message(
            '7f303295f59dba26d89a435bcae8671a157e09e0', 'The US'
          )}
        }}
        maxLength={200}
        onChange={handleChangeTag}
        onSelect={handleSelectTag}
        placeholder={Message(
          '982963c1c41cbed8cab073724749592fe35c532b', 'Tag'
        )}
        preicon={<FontAwesomeIcon icon={faTag} />}
        value={news.tag}
      />
      <div>
        <Text className='Small'>
          {Message('daaf5b931a14991da819dbe576492f9ed77517e2', 'Summary Image')}
        </Text>
        <div>
          <Image src={news.image} type='summary' />
          <div>
            <div>
              <Dropdown
                items={{
                  current: {label: Message(
                    'f0e58fc3c9d6a3ce86221a42fc40f2e21b05e6de',
                    'Keep current summary image'
                  )},
                  url: {label: Message(
                    '25265b96459a2c46c42cbf5f070f578336ce200c','Summary image URL'
                  )},
                  upload: {label: Message(
                    '89cb53e98610239ef63228da8b20aca1d67d71f7',
                    'Upload summary image'
                  )}
                }}
                onSelect={handleSelectImage}
                preicon={<FontAwesomeIcon icon={faImage} />}
                value={imageSelect}
              />
            </div>
            {contentImage()}
          </div>
        </div>
      </div>
      <Textarea
        maxLength={1000}
        onChange={handleChangeSummary}
        placeholder={Message(
          '12b71c3e0fe5f7c0b8d17cc03186e281412da4a8', 'Summary'
        )}
        value={news.summary}
      />
      <Dropdown
        items={{
          audio: {label: Message(
            'a72c39741706bb9f09933eb5c6a1c2981bc48371', 'Embed an audio'
          )},
          video: {label: Message(
            'e0ccc5db73a67993e7e609de83b52c1de30100db', 'Embed a video'
          )},
          link: {label: Message(
            '46d16a254f53b9ab2db1a4f80a0f4f3bd3d8157e', 'Post a link'
          )},
          post: {label: Message(
            '895a626de3e5147dbf397cb42949d46cc3f8cb3c', 'Compose a news'
          )}
        }}
        onSelect={handleSelectTemplate}
        placeholder={Message(
          '446a0c5f4ab3003f7cdc6e972fc7eee7782a7559', 'Select a news template'
        )}
        preicon={<FontAwesomeIcon icon={faNewspaper} />}
        value={news.template}
      />
      {content()}
      <Button disabled={handleDisabled()} onClick={handleClickSave}>
        <FontAwesomeIcon icon={faFloppyDisk} /> &nbsp;<Text>{Message(
          'efc007a393f66cdb14d57d385822a3d9e36ef873', 'Save'
        )}</Text>
      </Button>
    </nav>
  );
}
