// Eldhose John 20250205 @SumUps.com
import Text from './Text';

let rand = 0;
export default function Input(props) {
  const size = (typeof props.size === 'number') ? props.size : 7;
  const handleChange = (i, c) => {
    const str = props.value.substring(0, i) + c + props.value.substring(i + 1);
    props.onChange(str);
  }
  const handleKeyDown = (i, e) => {
    if(e.keyCode === 8) {
      handleChange(i - ((i >= props.value.length) ? 1 : 0), '');
      e.preventDefault();
    }
    else if(e.keyCode === 46) {
      handleChange(i, '');
      e.preventDefault();
    }
  }
  const placeholder = () => (
    (typeof props.placeholder === 'string') ?  props.placeholder : ''
  );
  const otp = [];
  for(let i = 0; i < size; i++)
    otp.push((i < props.value.length) ? props.value.charAt(i) : '');
  rand += size;
  return (
    <div>
      <Text className='Small'>{placeholder()}</Text>
      <div className='OTP'>
        {otp.map((c, i) => (
          <input
            autoFocus={i === props.value.length}
            key={rand + i}
            maxLength='1'
            onChange={(e) => handleChange(i, e.target.value)}
            onFocus={(e) => e.target.select()}
            onKeyDown={(e) => handleKeyDown(i, e)}
            type='text'
            value={c}
          />
        ))}
      </div>
    </div>
  );
}
