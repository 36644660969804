// John Eldhose 20240114 @SumUps.com
import { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faHouseCircleCheck, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { globalFunctionUse, globalPost } from '../lib/Global';
import { locationSet } from '../lib/Location';

let url;
export default function Search(props) {
  const Message = globalFunctionUse('Message');
  const getParms = (s) => {
    const p = {};
    if(s !== '') {
      for(const str of s.match(/([\S]*[\s]*=[\s]*"[^"]*")|([\S]*[\s]*=[\s]*[^\s]*)|("[^"]*")|([^\s]+)/g)) {
        let key = 'search';
        let val = str;
        if(str.includes('='))
          [key, val] = str.split(/[\s]*=[\s]*/);
        if(key !== '' && val !== '') {
          if(typeof p[key] === 'string' && p[key] !== '')
            p[key] += ' ' + val.replace(/"/g, '');
          else
            p[key] = val.replace(/"/g, '');
        }
      }
    }
    return p;
  }
  const getSearch = useCallback(() => {
    let s = '';
    if(props.url.page === '/' && typeof props.url.parms.nid === 'undefined') {
      for(const [key, val] of Object.entries(props.url.parms)) {
        if(s !== '') s += ' ';
        if(key !== 'search') s += key + '=';
        s += '"' + val + '"';
      }
    }
    return s;
  }, [props.url]);
  const [search, setSearch] = useState(getSearch());
  useEffect(() => {
    if(url !== window.location.href) {
      url = window.location.href;
      setSearch(getSearch());
    }
  }, [getSearch, setSearch]);
  const handleChange = (e) => (setSearch(e.target.value));
  const handleClickHome = (e) => {
    globalPost({mod: 'settings', settings: {
      home: ('/' + window.location.search)
    }}, (res) => {
      if(
        typeof res.transactionId === 'string' &&
        res.transactionId === 'cc702250293fb0a1714e080e1a756eb4a5b480df'
      )
        props.setHome('/' + window.location.search);
      else {
        const Message = globalFunctionUse('Message');
        const Notify = globalFunctionUse('Notify');
        Notify({
          message: Message(
            '4de04cd91a3d954b02c7397e263feddd8519c483', 'Update failed'
          ),
          type: 'Err'
        });
      }
    });
  }
  const handleClickSearch = () => {
    locationSet({page: '/', parms: getParms(search)});
  }
  const handleKeyDown = (e) => {
    if(e.key === 'Enter') {
      e.stopPropagation();
      handleClickSearch();
    }
  }
  return (
    <div className='Search Search-md Search-lg'>
      <button
        disabled={
          props.user === '' || props.url.page !== '/' ||
          typeof props.url.parms.nid !== 'undefined' ||
          props.home === ('/' + window.location.search)
        }
        onClick={handleClickHome}
      >
        <FontAwesomeIcon icon={
          (props.home === ('/' + window.location.search)) ? 
           faHouseCircleCheck : faHouse
        } />
      </button>
      <input
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={Message(
          '3cff86f40d6d624df1c434a1b68c3e7a1d5555b2', 'Search for news'
        )}
        type='text'
        value={search}
      />
      <button onClick={handleClickSearch}>
        <FontAwesomeIcon icon={faMagnifyingGlass} style={{
          backgroundColor: 'inherit',
          color: 'inherit'
        }} />
      </button>
    </div>
  );
}
