// Eldhose John 20240303 @SumUps.com
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faUnlock } from '@fortawesome/free-solid-svg-icons'
import { globalFunctionUse } from '../lib/Global';
import Input from '../elem/Input';

export default function Password(props) {
  const Message = globalFunctionUse('Message');
  const handleError = (psw) => {
    if(
      ((typeof props.emptyValid === 'boolean') ? props.emptyValid : false) &&
      psw === ''
    )
      props.setError(false);
    else
      props.setError(
        !(/^(?=.{7,}$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/.test(psw))
      );
  }
  const handleBlur = () => (handleError(props.password));
  const handleChange = (e) => {
    props.setPassword(e.target.value);
    handleError(e.target.value);
  }
  const placeholder = () => (
    (typeof props.placeholder === 'string') ? props.placeholder : Message(
      '8be3c943b1609fffbfc51aad666d0a04adf83c9d', 'Password'
    )
  )
  const [type, setType] = useState('password');
  const posticon = () => (
    <button
      className='ButtonIcon'
      onClick={(e) => (setType((type === 'password') ? 'text' : 'password'))}
    >
      {(type === 'password') ?
        <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />
      }
    </button>
  );
  return (
    <Input
      error={props.error}
      focus={typeof props.focus === 'boolean' && props.focus === true}
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder={placeholder()}
      posticon={posticon()}
      preicon={<FontAwesomeIcon icon={faUnlock} />}
      size={27}
      type={type}
      value={props.password}
    />
  );
}
