// Eldhose John 20240123 @SumUps.com
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import { globalFunctionUse, globalPost } from '../lib/Global';
import { locationLoad, locationSet } from '../lib/Location';
import Button from '../elem/Button';
import Email from '../user/Email';
import Password from '../user/Password';
import Text from '../elem/Text';

export default function Signin() {
  const Message = globalFunctionUse('Message');
  const [disabled, setDisabled] = useState(false)
  const [email, setEmail] = useState('');
  const [emailErr, setEmailErr] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordErr, setPasswordErr] = useState(false);
  const handleClick = () => {
    const Notify = globalFunctionUse('Notify');
    setDisabled(true);
    globalPost({mod: 'signin', email: email, password: password}, (res) => {
      if(typeof res.transactionId === 'string' && res.transactionId !== '') {
        let tid = res.transactionId;
        globalPost({
          mod: 'signin', transactionId: tid, email: email, password: password
        }, (res) => {
          if(typeof res.transactionId === 'string' && res.transactionId === tid)
            locationLoad('/');
          else
            Notify({
              message: Message(
                '2db8daa67b14f085b8dc6e7d26188ebe618b2a08',
                'Authentication failed'
              ),
              type: 'Err'
            });
          setDisabled(false);
        });
      }
      else {
        Notify({
          message: Message(
            '2db8daa67b14f085b8dc6e7d26188ebe618b2a08', 'Authentication failed'
          ),
          type:'Err'
        });
        setDisabled(false);
      }
    });
  }
  const handleDisabled = () => (
    disabled || email === '' || password === '' || emailErr || passwordErr
  );
  const handleRecover = (e) => {
    e.preventDefault();
    locationSet({page: '/recover', parms: {}});
  }
  const handleSignup = (e) => {
    e.preventDefault();
    locationSet({page: '/signup', parms: {}});
  }
  return (
    <nav className='Signin'>
      <Text className='Title'>{Message(
        'ada2e9e96fa9ce85430225b412068b5b62b79800', 'Sign in'
      )}</Text>
      <Email
        email={email}
        error={emailErr}
        focus={true}
        setEmail={setEmail}
        setError={setEmailErr}
      />
      <Password
        error={passwordErr}
        password={password}
        setPassword={setPassword}
        setError={setPasswordErr}
      />
      <div>
        <a
          className='AButton'
          href='/recover'
          onClick={handleRecover}
          tabIndex={-1}
        >
          {Message(
            '4c29f7f0335807c2524d8c36d531496aee23f473', 'Forgot password?'
          )}
        </a>
        <a
          className='AButton'
          href='/signup'
          onClick={handleSignup}
          tabIndex={-1}
        >
          {Message(
            'cbe72bf10419454753276dd45b631d3327000f36','Do not have an account?'
          )}
        </a>
      </div>
      <Button disabled={handleDisabled()} onClick={handleClick}>
        <FontAwesomeIcon icon={faRightToBracket} />&nbsp;{Message(
          'ada2e9e96fa9ce85430225b412068b5b62b79800', 'Sign in'
        )}
      </Button>
    </nav>
  );
}
