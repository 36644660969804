// Eldhose John 20240211 @SumUps.com
import Error    from '../rout/Error';
import Feedback from '../rout/Feedback';
import News     from '../rout/News';
import Profile  from '../rout/Profile';
import Recover  from '../rout/Recover';
import Report   from '../rout/Report';
import Settings from '../rout/Settings';
import Signin   from '../rout/Signin';
import Signup   from '../rout/Signup';
import '../css/Route.css';

export default function Route(props) {
  if(props.error !== '')
    return <Error error={props.error} />;
  else if([
    '/contactus', '/featurerequest', '/reportaproblem'
  ].includes(props.url.page))
    return <Feedback url={props.url} />;
  else if(props.user === '') {
    switch(props.url.page) {
      case '/recover':
        return <Recover/>;
      case '/report':
      case '/signin':
        return <Signin />;
      case '/signup':
        return <Signup />;
      case '/':
      default:
        return (
          <News
            data={props.data} news={props.news} url={props.url}
            user={props.user}
          />
        );
    }
  }
  else {
    switch(props.url.page) {
      case '/profile':
        return <Profile />;
      case '/report':
        if(typeof props.data.report === 'object')
          return <Report data={props.data.report} />;
        else
          return <div />
      case '/settings':
        if(typeof props.data.settings === 'object')
          return <Settings data={props.data.settings} />;
        else
          return <div />
      case '/':
      default:
        return (
          <News
            data={props.data} news={props.news} url={props.url}
            user={props.user}
          />
        );
    }
  }
}
