// Eldhose John 20240123 @SumUps.com
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { globalFunctionUse, globalPost } from '../lib/Global';
import { locationSet } from '../lib/Location';
import Button from '../elem/Button';
import Email from '../user/Email';
import Password from '../user/Password';
import Text from '../elem/Text';
import User from '../user/User';

export default function Signup() {
  const Message = globalFunctionUse('Message');
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState('');
  const [emailErr, setEmailErr] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordErr, setPasswordErr] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [user, setUser] = useState('');
  const [userErr, setUserErr] = useState('');
  const body = () => (
    <>
      <Email
        email={email}
        focus={true}
        error={emailErr}
        setEmail={setEmail}
        setError={setEmailErr}
      />
      <Password
        error={passwordErr}
        password={password}
        setPassword={setPassword}
        setError={setPasswordErr}
      />
      <User
        error={userErr}
        setUser={setUser}
        setError={setUserErr}
        user={user}
      />
    </>
  );
  const [confirmEmail, setConfirmEmail] = useState('');
  const [confirmEmailErr, setConfirmEmailErr] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordErr, setConfirmPasswordErr] = useState('');
  const confirmBody = () => (
    <>
      <Email
        email={confirmEmail}
        error={confirmEmailErr}
        placeholder={Message(
          '0e4f1e7ca014c23bb42c16bf0d32af27c235c4ab', 'Confirm email address'
        )}
        setEmail={setConfirmEmail}
        setError={setConfirmEmailErr}
      />
      <Password
        error={confirmPasswordErr}
        password={confirmPassword}
        placeholder={Message(
          '4a7c565d4c4430e3bb8fa6c560125d5eb37e7c3d', 'Confirm password'
        )}
        setPassword={setConfirmPassword}
        setError={setConfirmPasswordErr}
      />
    </>
  );
  const handleClick = () => {
    const Notify = globalFunctionUse('Notify');
    setDisabled(true);
    if(transactionId === '') {
      globalPost({mod: 'signup', email: email, password: password, user: user},
      (res) => {
        if(typeof res.transactionId === 'string' && res.transactionId !== '') {
          if(res.transactionId === '6ba1afc7b5a9b55a5a93a473c0e8f57fa4eb6ccc')
            Notify({
              message: Message(
                '6ba1afc7b5a9b55a5a93a473c0e8f57fa4eb6ccc',
                'User name is already in use. Please select a different user name'
              ),
              type: 'Warn'
            });
          else if(
            res.transactionId === '474d083144758ae02fc3428e87962ed87b18774b'
          )
            Notify({
              message: Message(
                '474d083144758ae02fc3428e87962ed87b18774b',
                'An account already exist with that email address'
              ),
              type: 'Warn'
            });
          else
            setTransactionId(res.transactionId);
        }
        setDisabled(false);
      });
    }
    else {
      globalPost({
        mod: 'signup', email: email, password: password,
        transactionId: transactionId, user: user
      }, (res) => {
        if(
          typeof res.transactionId === 'string' &&
          res.transactionId === transactionId
        ) {
          Notify({
            message: Message(
              '0007d39aa7ec3399ee0dc2a3d6b90b4dfeba440c',
              'Account has been created successfully'
            ),
            type: 'Ok'
          });
        }
      });
    }
  }
  const handleDisabled = () => (
    disabled || email === '' || password === '' || user === '' ||
    emailErr || passwordErr || userErr || (transactionId !== '' && (
      email !== confirmEmail || password !== confirmPassword
    ))
  );
  const handleRecover = (e) => {
    e.preventDefault();
    locationSet({page: '/recover', parms: {}});
  }
  return (
    <nav className='Signup'>
      <Text className='Title'>{Message(
        '4c4773e451c399785b926de45d2e3aa7d3c68030', 'Sign up for a new account'
      )}</Text>
      {(transactionId === '') ? body() : confirmBody()}
      <a
        className='AButton' href='/recover' onClick={handleRecover} tabIndex={-1}
      >
        {Message(
          '744f8c3b4410647a2c9f64f92691a196c1d095ea', 'Recover existing account'
        )}
      </a>
      <Text className='Caption'>{Message(
        'fbb9fe9c3799f8f2f01b0a705898622ccafa7128',
        'Password & User requires at least six letters and one digit'
      )}</Text>
      <Button disabled={handleDisabled()} onClick={handleClick}>
        <FontAwesomeIcon icon={faPenToSquare} />&nbsp;{Message(
          '0b81497c8589af2714a1ddbeb62b3a4be679c318', 'Sign up'
        )}
      </Button>
    </nav>
  );
}
