// John Eldhose 20240128 @SumUps.com
import { useEffect, useRef, useState } from 'react';
import Dropdown from '../elem/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-regular-svg-icons';
import { faFlag as faFlagSolid } from '@fortawesome/free-solid-svg-icons';
import { globalFunctionUse, globalPost } from '../lib/Global';
import Rating from '../elem/Rating';
import Text from '../elem/Text';

export default function View(props) {
  const Message = globalFunctionUse('Message');
  const contentRef = useRef(null);
  const getRanking = (elem) => {
    if(typeof props.data[elem] === 'object')
      return props.data[elem];
    return {flag: '', rating: 0};
  }
  const [ranking, setRanking] = useState(getRanking('ranking'));
  const rankingSummary = getRanking('rankingSummary');
  const getNews = (elem) => {
    if(typeof props.data.news[elem] === 'undefined')
      return '';
    return props.data.news[elem];
  }
  useEffect(() => {
    if(contentRef.current !== null) {
      if(getNews('template') === 'link')
        contentRef.current.innerHTML = '<a href="' + getNews('details') + '" target="_blank">' + getNews('details') +'</a>';
      else if(getNews('template') === 'video')
        contentRef.current.innerHTML = '<video width="560"><source src="' + getNews('details') + '" /></video>';
      else
        contentRef.current.innerHTML = getNews('details');
    }
  });
  const fixRanking = (rank) => {
    globalPost({
      mod: 'news', act: 'ranking', nid: getNews('nid'), flag: rank.flag,
      rating: rank.rating
    }, (res) => {});
    setRanking(rank);
  }
  const getRankingSummaryFlag = () => {
    if(rankingSummary.flag === 'bigotry')
      return (Message(
        'f8c928d93217604745014768783edf00fe0aee42', 'Bigotry'
      ))
    else if(rankingSummary.flag === 'child')
      return (Message(
        'a956a1263f4d5bbc8b6c544d7f119be56e0d4fb1', 'Child abuse'
      ))
    else if(rankingSummary.flag === 'explicit')
      return (Message(
        '53e341cf22685ee4d4e504cb71f577e22693b17c', 'Explicit material'
      ))
    else
      return (Message(
        '6eef6648406c333a4035cd5e60d0bf2ecf2606d7', 'None'
      ));

  }
  const handleClick = (key) => (fixRanking({flag: '', rating: key}));
  const handleSelect = (key) => (fixRanking({flag: key, rating: 0}));
  return (
    <div className='NewsView'>
      <div>
        <Text className='Small'>
          Highest flag:&nbsp;{getRankingSummaryFlag()} |
          Average rating:&nbsp;<Rating disabled={true} rating={rankingSummary.rating} />
        </Text>
      </div>
      <div><Text className='Title'>{getNews('title')}</Text></div>
      <div>
        <Text className='Small'>{getNews('reporter')}</Text>
        <Text className='Small'>{getNews('tag')}</Text>
        <Text className='Small'>{getNews('date')}</Text>
      </div>
      <div ref={contentRef} />
      <div>
        <div>
          <Dropdown
            items={{
              bigotry: {label: Message(
                'f8c928d93217604745014768783edf00fe0aee42', 'Bigotry'
              )},
              child: {label: Message(
                'a956a1263f4d5bbc8b6c544d7f119be56e0d4fb1', 'Child abuse'
              )},
              explicit: {label: Message(
                '53e341cf22685ee4d4e504cb71f577e22693b17c',
                'Explicit material'
              )}
            }}
            placeholder={Message(
              '874833e7390b176bb290398a79665478df3b2be3', 'Flag this report'
            )}
            onSelect={handleSelect}
            preicon={
              (ranking.flag === '') ?
                <FontAwesomeIcon icon={faFlag} /> :
                <FontAwesomeIcon icon={faFlagSolid} className='NewsViewFlag' />
            }
            value={ranking.flag}
          />
          <div>
            <div>
              <Text className='Small'>
                {Message(
                  '680c5177e54f0f5f2190b504cbda82da9b30cfae',
                  'Rate this report'
                )}
              </Text>
            </div>
            <Rating
              onClick={handleClick}
              rating={ranking.rating}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
