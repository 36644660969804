// John Eldhose 20240114 @SumUps.com
import { globalFunctionUse } from '../lib/Global';
import { locationSet } from '../lib/Location';
import Text from '../elem/Text';

export default function Footer() {
  const Message = globalFunctionUse('Message');
  const handleContactus = (e) => {
    e.preventDefault();
    locationSet({page: '/contactus', parms: {}});
  }
  const handleFeature = (e) => {
    e.preventDefault();
    locationSet({page: '/featurerequest', parms: {}});
  }
  const handleProblem = (e) => {
    e.preventDefault();
    locationSet({page: '/reportaproblem', parms: {}});
  }
  return (
    <nav className='Footer Footer-sm Footer-md'>
      <div>
        <a href='/contactus' onClick={handleContactus}>{Message(
          '4832e45812a2724f16a15f9ae87adfc8ae4168cf', 'Contact us'
        )}</a>
        <a href='/featurerequest' onClick={handleFeature}>{Message(
          '94ddc396170220f85217f31e933130367f8fde81', 'Feature request'
        )}</a>
        <a href='/reportaproblem' onClick={handleProblem}>{Message(
          '24f3f52de52ef0709de0c798934acad15b619528', 'Report a problem'
        )}</a>
      </div>
      <Text className='Small'>
        {'© SumUps.com ' + (new Date().getFullYear())}
      </Text>
    </nav>
  );
}
