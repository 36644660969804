// Eldhose John 20240114 @SumUps.com
import React, { useState } from 'react';
import { globalFunctionSet, globalPost } from './lib/Global';
import { locationGet } from './lib/Location';
import Footer from './main/Footer';
import Left from './main/Left';
import Loading from './main/Loading';
import Message from './lib/Message';
import Navbar from './main/Navbar';
import Notify from './main/Notify';
import Refresh from './lib/Refresh';
import Right from './main/Right';
import Route from './main/Route';
import Style from './lib/Style';
import './css/Main.css';
import './css/Elem.css';

let init = true;
export default function Main() {
  const [data, setData] = useState({});
  const [error, setError] = useState('');
  const [home, setHome] = useState('');
  const [loading, setLoading] = useState(0);
  const [message, setMessage] = useState({});
  const [news, setNews] = useState([]);
  const [style, setStyle] = useState();
  const [user, setUser] = useState('');
  const [url, setURL] = useState(locationGet());
  globalFunctionSet('Error', (err) => setError(err));
  globalFunctionSet('Loading', (load) => setLoading(load));
  if(init) {
    init = false;
    globalPost({mod: 'style'}, (res) => {
      if(typeof res.style === 'object')
        setStyle(res.style);
      globalPost({mod: 'init'}, (res) => {
        if(typeof res.user === 'string' && res.user !== '')
          setUser(res.user);
        if(typeof res.home === 'string' && res.home !== home)
          setHome(res.home);
        globalPost({mod: 'message'}, (res) => {
          if(typeof res.message === 'object')
            setMessage(res.message);
          const keys = (
            (typeof url.parms === 'object') ? Object.keys(url.parms).length : 0
          );
          if(
            typeof url.parms.nid === 'string' &&
            (keys === 1 || (keys === 2 && typeof url.parms.title === 'string'))
          )
            globalPost({mod: 'news', act: 'get', nid: url.parms.nid}, (res) => {
              setData({view: res});
            }, true);
          else
            globalPost({mod: 'news', parms: url.parms}, (res) => {
              if(typeof res.news === 'object') {
                setNews(res.news);
                setData({
                  lastNewsLength: res.news.length, lastNewsTime: Date.now()
                });
              }
            }, true);
        }, true);
      }, true);
    }, true);
  }
  Message(message); Style(style);
  Refresh({
    news: news, setData: setData, setNews: setNews, setURL: setURL, url: url
  });
  return (
    <React.StrictMode>
      <div className='Main Main-md Main-xl'>
        <Loading loading={loading} />
        <Navbar home={home} setHome={setHome} url={url} user={user} />
        <div>
          <Left />
          <div>
            <Route data={data} error={error} news={news} url={url} user={user}/>
          </div>
          <Right />
        </div>
        <Footer />
        <Notify />
      </div>
    </React.StrictMode>
  );
}
