// Eldhose John 20240122 @SumUps.com

if(typeof window.MRN !== 'object')
  window.MRN = {}
export function globalFunctionSet(func, def) {
  if(typeof func === 'string' && typeof def === 'function')
    window.MRN[func] = def;
}
export function globalFunctionUse(func) {
  if(typeof window.MRN === 'object' && typeof window.MRN[func] === 'function')
    return window.MRN[func];
  return false;
}
let Cache_Control = (str, func) => func('a54d88e06612d820bc3be72877c74f257b561b19');
let randoms = [
'',
'43616368655f436f6e74726f6c3d287374722c66756e63293d3e2863727970746f2e737562746c652e64696765737428275348412d31272c6e65772054657874456e636f64657228292e656e636f646528',
'',
'272b',
'73747229292e7468656e2828627566293d3e66756e632841727261792e66726f6d286e65772055696e743841727261792862756629292e6d6170282862293d3e28622e746f537472696e67283136292e706164537461727428322c2730272929292e6a6f696e28272729',
'2e736c69636528302c2d31292b27',
'292929'
];
const reload = (rl) => {
  if(rl === 1)
    randoms[0] = randoms[1] + randoms[4] + randoms[6];
  if(rl === 2)
    randoms[0] = randoms[1] + randoms[4] + randoms[5] + '3127' + randoms[6];
  if(rl === 3)
    randoms[0] = randoms[1] + randoms[4] + randoms[5] + '6c27' + randoms[6];
  if(rl === 4)
    randoms[0] = randoms[1] +'27'+randoms[2]+randoms[3]+randoms[4]+ randoms[6];
/* eslint no-eval: 0 */
  eval(Array.from(randoms[0].match(/.{1,2}/g).map(
    (b) => (String.fromCharCode(parseInt(b, 16)))
  )).join(''));
}
const reloadx = (res) => {
  randoms[2] = Array.from(new TextEncoder().encode(res['Cache-Control'])).map(
    (b)=>(b.toString(16))
  ).join('');
}
let load = 0, posts = 0;
export function globalPost(body, func, init) {
  const Error = globalFunctionUse('Error');
  const Notify = globalFunctionUse('Notify');
  if(typeof func === 'function') {
    window.MRN.Loading(++posts);
    body.ts = Date.now(); if(init) reload(++load);
    Cache_Control(JSON.stringify(body), (cc) => (
      fetch('/v1/api/main.php', {
        body: JSON.stringify({body: body, 'Cache-Control': cc}),
        headers: {}, method: 'POST'
      })
        .then((rRes) => rRes.json())
        .then((res) => {
          if(typeof res === 'object') {
            if(typeof res.status === 'number' && res.status === 0) {
              if(typeof res.body === 'object') {
                if(typeof res.body.message === 'object') reloadx(res);
                func(res.body);
              }
              else
                func({});
            }
            else if(
              typeof res.body === 'object' &&
              typeof res.body.message === 'string' &&
              typeof res.body.type === 'string'
            )
              Notify({message: res.body.message, type: res.body.type});
            else
              Error('Error bad data received');
          }
          else
            Error('Error failed to read data');
          if(--posts < 0) posts = 0;
          window.MRN.Loading(posts);
        })
        .catch((err) => {
          Error('Error communicating with server');
          if(--posts < 0) posts = 0;
          window.MRN.Loading(posts);
        })
    ));
  }
}
