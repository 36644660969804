// John Eldhose 20240114 @SumUps.com
import ProfileMenu from './ProfileMenu';
import Search from '../elem/Search';

export default function Navbar(props) {
  return (
    <nav className='Navbar Navbar-sm Navbar-md'>
      <a href={props.home}><img src='/logo.png' alt='logo' /></a>
      <Search
        home={props.home} setHome={props.setHome}
        user={props.user} url={props.url}
      />
      <ProfileMenu user={props.user} />
    </nav>
  );
}
