// John Eldhose 20250105 @SumUps.com
import { globalFunctionSet, globalPost } from './Global';

export default function Refresh(props) {
  let lastUrl = props.url;
  globalFunctionSet('Refresh', (obj) => {
    props.setData({});
    if(obj.page === '/report') {
      const nid = (
        typeof obj.parms === 'object' && typeof obj.parms.nid === 'string'
      ) ? obj.parms.nid : 'new';
      if(nid === 'new')
        props.setData({report: {}});
      else
        globalPost({mod: 'news', act: 'get', nid: nid, fix: true}, (res) => {
          props.setData({report: res});
        });
    }
    else if(obj.page === '/settings')
      globalPost({mod: 'settings'}, (res) => (props.setData({settings: res})));
    else {
      const keys = (
        (typeof obj.parms === 'object') ? Object.keys(obj.parms).length : 0
      );
      if(
        typeof obj.parms.nid === 'string' &&
        (keys === 1 || (keys === 2 && typeof obj.parms.title === 'string'))
      )
        globalPost({mod: 'news', act: 'get', nid: obj.parms.nid}, (res) => {
          props.setData({lastUrl: lastUrl, view: res});
        });
      else if(typeof obj.parms.preceding === 'string')
        globalPost({mod: 'news', parms: obj.parms}, (res) => {
          if(typeof res.news === 'object') {
            props.setNews(props.news.concat(res.news));
            props.setData({lastNewsLength: res.news.length});
            
          }
        });
      else
        globalPost({mod: 'news', parms: obj.parms}, (res) => {
          if(typeof res.news === 'object')
            props.setNews(res.news);
            props.setData({
              lastNewsLength: res.news.length, lastNewsTime: Date.now()
            });
        });
    }
    props.setURL(obj);
    lastUrl = props.url;
  });
}
