// John Eldhose 20241210 @SumUps.com
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';

export default function Rating(props) {
  const [rating, setRating] = useState(0);
  const getRating = (i) => {
    if(i <= ((rating === 0) ? props.rating : rating))
      return (<FontAwesomeIcon icon={faStarSolid} className='RatingStar' />);
    else
      return (<FontAwesomeIcon icon={faStar} />);
  }
  const handleClick = (i) => {
    if(typeof props.onClick === 'function')
      props.onClick(i);
    setRating(0);
  }

  return (
    <div className='Rating'>
      {[1, 2, 3, 4, 5].map((i) => {
        if(typeof props.disabled === 'boolean' && props.disabled === true)
          return (<div key={i}>{getRating(i)}</div>);
        else
          return (
            <div
              className='Rating Enabled'
              key={i}
              onClick={(e) => handleClick(i)}
              onMouseOut={(e) => (setRating(0))}
              onMouseOver={(e) => (setRating(i))}
            >
              {getRating(i)}
            </div>
          );
      })}
    </div>
  );
}
